import {
  initializeAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  browserLocalPersistence,
} from "@firebase/auth";
import { getStorage, ref, listAll, getBlob } from "@firebase/storage";
import { initializeApp } from "@firebase/app";

const MAX_TAB = 7;

const canvasEl = document.getElementById("canvas") as HTMLDivElement;
const canvasImgEl = document.getElementById("canvasImg") as HTMLImageElement;
const backEl = document.getElementById("back") as HTMLLinkElement;
const nextEl = document.getElementById("next") as HTMLLinkElement;
const picturesEl = document.getElementById("pictures");

const app = initializeApp({
  apiKey: "AIzaSyCkU_5w7y_zdZLN-c-qaOp7wjOcJ8yOE38",
  authDomain: "milaschmidt-ca.firebaseapp.com",
  databaseURL: "https://milaschmidt-ca.firebaseio.com",
  messagingSenderId: "777875269742",
  projectId: "milaschmidt-ca",
  storageBucket: "milaschmidt-ca.appspot.com",
});
const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});
const storage = getStorage();

let loggedIn = false;

let currentDay = 0;
let currentMonth = 0;
let currentYear = 0;
let currentAlbum = null;
let currentPhoto = null;

let maxPhoto = 0;

let xDown = null;
let yDown = null;

function day(id) {
  if (currentDay) {
    document.getElementById("day" + currentDay).style.backgroundColor = "white";
  }
  currentDay = id;
  document.getElementById("day" + currentDay).style.backgroundColor =
    "darkgray";
}

function month(id) {
  if (currentMonth) {
    document.getElementById("month" + currentMonth).style.backgroundColor =
      "white";
  }
  currentMonth = id;
  document.getElementById("month" + currentMonth).style.backgroundColor =
    "darkgray";
}

function year(id) {
  if (currentYear) {
    document.getElementById("year" + currentYear).style.backgroundColor =
      "white";
  }
  currentYear = id;
  document.getElementById("year" + currentYear).style.backgroundColor =
    "darkgray";
}

function unlock() {
  const username = location.hostname.indexOf("mila") !== -1 ? "devnull@milaschmidt.com" : "devnull@liamschmidt.co"
  signInWithEmailAndPassword(
    auth,
    username,
    `${currentDay}.${currentMonth}.${currentYear}`
  ).catch(() => {
    document.getElementById("error").style.display = "block";
  });
}

function close() {
  canvasEl.style.visibility = "hidden";
  canvasImgEl.style.opacity = "0.0";
  currentPhoto = null;
}

function next() {
  if (currentAlbum && currentPhoto !== null && currentPhoto !== maxPhoto) {
    ++currentPhoto;
    show(currentAlbum, currentPhoto);
  }
}

function back() {
  if (currentAlbum && currentPhoto) {
    --currentPhoto;
    show(currentAlbum && currentAlbum, currentPhoto);
  }
}

async function show(albumId, photoId) {
  const el = document.getElementById(`thumb${photoId}`) as HTMLImageElement;
  const imageName = el.alt;

  canvasEl.style.visibility = "visible";
  canvasImgEl.style.opacity = "0";

  const blob = await getBlob(ref(storage, `${albumId}/${imageName}`));

  const img = new Image();
  img.addEventListener("load", () => {
    let w = window.innerWidth - 100;
    let h = window.innerHeight - 100;

    let wScale = w / img.width;
    let hScale = h / img.height;

    if (wScale < 1 || hScale < 1) {
      if (wScale < hScale) {
        h = img.height * wScale;
        w = img.width * wScale;
      } else {
        h = img.height * hScale;
        w = img.width * hScale;
      }
    } else {
      h = img.height;
      w = img.width;
    }

    canvasEl.style.marginLeft = `-${w / 2 + 20}px`;
    canvasEl.style.marginTop = `-${h / 2 + 20}px`;

    canvasImgEl.style.width = `${w}px`;
    canvasImgEl.style.height = `${h}px`;

    currentAlbum = albumId;
    currentPhoto = photoId;

    if (currentPhoto == 0) {
      backEl.onclick = () => {};
      backEl.style.color = "gray";
    } else {
      backEl.style.color = "black";
      backEl.onclick = () => back();
    }

    if (currentPhoto == maxPhoto) {
      nextEl.style.color = "gray";
      nextEl.onclick = () => {};
    } else {
      nextEl.style.color = "black";
      nextEl.onclick = () => next();
    }
	
	canvasImgEl.src = img.src;
	canvasImgEl.style.transition = "";
	canvasImgEl.style.opacity = "1";
	canvasImgEl.style.transition = "opacity 0.5s";
  });

  img.src = URL.createObjectURL(  blob  );
}

async function load(albumId) {
  close();

  if (loggedIn) {
    picturesEl.innerHTML = "<p>Loading...</p>";
    picturesEl.style.display = "block";

    while (picturesEl.firstChild) {
      picturesEl.removeChild(picturesEl.firstChild);
    }

    document.getElementById("link" + albumId).classList.add("selected");
    document.getElementById("link" + albumId).classList.remove("background");

    if (currentAlbum !== null) {
      document
        .getElementById("link" + currentAlbum)
        .classList.remove("selected");
      document
        .getElementById("link" + currentAlbum)
        .classList.add("background");
    }

    const items = (await listAll(ref(storage, albumId + "/thumbs"))).items;
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
	items.sort((a, b) => collator.compare(a.name, b.name));
	
    for (let i = 0; i < items.length; ++i) {
      const img = document.createElement("img");
      img.src = "/blank.gif";
      img.onclick = () => {
        if (currentPhoto === null) show(albumId, i);
      };
      img.alt = items[i].name;
      img.id = "thumb" + i;
      img.classList.add("thumbnail");
      getBlob(items[i]).then((blob) => {
        img.src = URL.createObjectURL(blob);
      });
      picturesEl.appendChild(img);
    }

    maxPhoto = items.length - 1;
    currentAlbum = albumId;
  }
}

function onCanvasClicked(e: MouseEvent) {
  const w = (e.target as HTMLImageElement).width;
  const rect = (e.target as HTMLImageElement).getBoundingClientRect();
  const l = e.clientX - rect.left;

  if (l < w / 3) {
    back();
  } else if (l > (w * 2) / 3) {
    next();
  }
}

function handleTouchStart(evt) {
  const firstTouch = evt.touches[0];
  xDown = firstTouch.clientX;
  yDown = firstTouch.clientY;
}

function handleTouchMove(evt) {
  if (!xDown || !yDown) {
    return;
  }

  var xUp = evt.touches[0].clientX;
  var yUp = evt.touches[0].clientY;

  var xDiff = xDown - xUp;
  var yDiff = yDown - yUp;

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff > 10) {
      next();
    } else {
      back();
    }
  }

  xDown = null;
  yDown = null;
}

onAuthStateChanged(auth, (user) => {
  if (user) {
    loggedIn = true;

    document.getElementById("login").style.display = "none";

    let removed = true;

    var n = document.getElementsByClassName("inactive");
    for (var i = 0; i < n.length; ) {
      n[i].classList.remove("inactive");
    }

    load(MAX_TAB);
  }
});

document.addEventListener("keydown", (key) => {
  if (key.code == "ArrowLeft") {
    back();
  }

  if (key.code == "ArrowRight") {
    next();
  }
});

document.addEventListener("click", (e) => {
  if (
    canvasEl !== e.target &&
    !canvasEl.contains(e.target as Node) &&
    currentPhoto !== null
  ) {
    close();
  }
});

document.addEventListener("touchstart", handleTouchStart, false);
document.addEventListener("touchmove", handleTouchMove, false);

for (let i = 1; i <= 31; ++i) {
  document.getElementById("day" + i).onclick = () => day(i);
}

for (let i = 1; i <= 12; ++i) {
  document.getElementById("month" + i).onclick = () => month(i);
}

for (let i = 2017; i <= 2024; ++i) {
  const button = document.getElementById("year" + i);
  if (button) button.onclick = () => year(i);
}

for (let i = 1; i <= MAX_TAB; ++i) {
  document.getElementById("link" + i).onclick = () => load(i);
}

document.getElementById("unlock").onclick = () => unlock();
document.getElementById("back").onclick = () => back();
document.getElementById("next").onclick = () => next();
document.getElementById("close").onclick = () => close();
document.getElementById("canvasImg").onclick = (e) => onCanvasClicked(e);
